import React from "react"
import { graphql } from 'gatsby'
import EventLayout from "../../../components/layouts/eventLayout"
import Button from "../../../components/button"
import Chamber from "../../../images/peaceloverun.png" 
const breakpoints = [980]
const mq = breakpoints.map(
    bp => `@media (min-width: ${bp}px)`
)


const CurisinInTheCountryRunPage = ({data, ...props}) => {
  
  return(
  <EventLayout
    pageTitle="Cruisin’ in the Country"
    tabs={[
      { to: "/programs/cruisin-in-the-country", text: "Overview" },
      {
        to: "/programs/cruisin-in-the-country/routes",
        text: "Routes",
      },
      {
        to: "/programs/cruisin-in-the-country/registration",
        text: "Registration",
      },
      {
        to: "/programs/cruisin-in-the-country/accomodations",
        text: "Accommodations",
      },
      {
        to: "/programs/cruisin-in-the-country/run",
        text: "Run",
      },
    ]}
  >
        <img
        src={Chamber}
        css={{
          display: "inline-block",
          border: "1px solid #000",
          width: "50%",
          float: "left",
          marginRight: "1rem",
          marginBottom: `1rem`,
          "@media (min-width: 800px)": {
            width: `45%`,
            marginBottom: `0`,
          },
        }}
      />
    <h2 css={{marginBottom: `2rem`}}>Cruisin’ in the Country 5K & 10K RUN!</h2>
    
    <p>
    The Claxton-Evans County Chamber of Commerce and Evans County Charter School System are proud to sponsor the Cruisin’ in the Country 5K & 10K RUN, Saturday, November 12 th , 2022.
<br /> <br />
This year’s event will be headquartered at the Evans County Public Fishing Area, 4757 Area Line Road, Claxton, Georgia. This beautiful 372- acre site is owned and operated by the Georgia Department of Natural Resources and features a campground with 22 campsites complete with electricity, water, picnic table, fire ring and a comfort station with showers. Primitive campsites are also available. For camping information or to make a reservation, log on to http://reservations.gooutdoorsgeorgia.com.
    </p><br />
    <p>
    All routes begin and end at Cruisin’ Headquarters, Evans County Public Fishing Area Event Center, 4757 Area Line Road, Claxton, Georgia.  
    </p>
    <h3 css={{fontSize: `1.25rem`, margin: `2rem 0 0.5rem 0`}}>Route Options</h3>
    <p>
    Cruisin’ is a family friendly Run. Three different run options will be offered to ensure there’s a run suited for any skill level. Whatever route you choose, you will travel roads with minimal traffic and enjoy the beautiful countryside. The route options include a: 5K off-road cross country, 5 K paved road, and a 10K paved road run.
    </p>
    <h3 css={{fontSize: `1.25rem`, margin: `2rem 0 0.5rem 0`}}>Registration Options</h3>
    <p>
    We are pleased to offer 3 registration schedules for the Cruisin’ RUN. Early Bird registration is $40 and will be offered August 15 until October 31st. The registration fee for November 1st until November 12th is $50 (including walk-ups.) Active and retired military can register for $40, at any time. ( Please note – event t-shirts CANNOT be guaranteed for registrations received after November 7th .) You can download a registration form (www.claxtonevanschamber.com) and mail in with payment. To register by credit card, log on to www. Active.com. Search for Cruisin’ in the Country 5K & 10K RUN. On-site registration will be offered at Cruisin’ Headquarters, Saturday, November 12th, beginning at 9:00 a.m. Run begins at 10 a.m.
<br /><br />
All routes begin and end at Cruisin’ Headquarters, Evans County Public Fishing Area Event Center, 4757 Area Line Road, Claxton, Georgia.
<br /><br />
For additional information, please contact the Claxton-Evans County Chamber of Commerce, info@claxtonevanschamber.com, or (912) 739-1391
    </p>
    <div css={{margin: `2rem 0`}}>
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2022CruisinRunApplication.pdf">Download PDF Registration</a>
      <Button to="https://www.active.com/claxton-ga/running/distance-running/cruisin-in-the-country-5k-and-10k-run-2022?int=72-3-A1" text="Register Online" />
    </div>
  </EventLayout>
)
}



export default CurisinInTheCountryRunPage